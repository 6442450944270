.blc_card {
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 2px 0px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(204, 204, 204, 0.8);
  color: #000 !important;
}

.blc_button {
  font-size: 0.75rem !important;
}

.blc_nav_active {
  border-bottom: 3px solid #0D0C0A;
  background: transparent !important;
  color: #0D0C0A !important;
  border-radius: 0px !important;
}

.blc_event_footer {

  background: #80808014;

}

.blc_event_blc {

  background: transparent;

  padding-left: 4px;

  padding-right: 4px;

  padding-top: 8px;

  padding-bottom: 8px;

}

.fa-1x {
  font-size: 1rem !important;
}


/*!
 * Vista Custom Styles
 */
.bl_H1_Color {
  color: #070B31 !important;
}

.vista_H2_Color {
  color: #333333;
}

.vista_link_Color {
  color: #47ABE1;
}

.vista_no_padding_right {
  padding-right: 0 !important;
}

/* --login page styles-- */
.bl-login-bg {
  /* background: url('../assets/img/BG.webp') no-repeat center center fixed; */
  background: radial-gradient(circle at 100% 100%, rgba(184, 132, 186, 0.29) 0%, 15.75%, rgba(184, 132, 186, 0) 35%), radial-gradient(circle at 38.88671875% 46.334635416666664%, #FBF5EE 0%, 32.5%, rgba(251, 245, 238, 0) 65%), radial-gradient(circle at 94.78190104166667% 3.470052083333333%, rgba(250, 211, 117, 0.22) 0%, 41.99999999999999%, rgba(250, 211, 117, 0) 60%), radial-gradient(circle at 10.5615234375% 100%, #FAC0C0 0%, 25%, rgba(250, 192, 192, 0) 50%), radial-gradient(circle at 0.03255208333333333% 1.4127604166666667%, #EBDDFF 0%, 35%, rgba(235, 221, 255, 0) 50%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FBF5EE 0%, 100%, rgba(251, 245, 238, 0) 100%);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.bl-login-card {
  background-color: #0405068a !important;
}

.vista_login_formElements {
  border-radius: 0.3rem !important;
}

.vista_disabled {
  opacity: 0.4;
}

.vista_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blc_card_head {
  color: #565555;
  font-weight: bold;
}


/* --collapsing styles and logic-- */
@media (min-width: 768px) {
  .vistaContentMargin {
    margin-top: 55px;
    margin-left: 224px;
  }

  .vistaCollapsedContentMargin {
    margin-left: 64px !important;
  }

  .vistaHeaderMargin {
    left: 219px;
  }

  .vistaCollapsedHeaderMargin {
    left: 64px !important;
  }

}

@media (max-width: 768px) {
  .vistaContentMargin {
    margin-top: 55px;
    margin-left: 0px;
  }

  .vistaHeaderMargin {
    left: 100px;
  }

  .vistaCollapsedHeaderMargin {
    left: 0px !important;
  }

}

/*  ---- thumbnail checkboxes-------- */
.vista_thumb_container {
  position: relative;
}

.vista_thumb_checkbox {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
  cursor: pointer;
}

.vista_thumb_height {
  height: 50px !important;
  opacity: 0.6;
}

.vista_thumb_height:hover {
  filter: invert(1);
  cursor: pointer;
}

.vista_thumb_active {
  filter: invert(1);
}

/*  ---- nav icons-------- */
.vista_nav_icon {
  height: 15px !important;
  filter: contrast(0.3);
  margin-right: 5px;
}

.vista_nav_icon_active {
  filter: invert(1);
}

/*  ---- timeline styles-------- */
ul.timeline {
  list-style-type: none;
  position: relative;
  font-size: 12px;
  margin-left: -18px;
}

ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline>li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline>li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #E94359;
  left: 22px;
  width: 15px;
  height: 15px;
  z-index: 400;
}

.timelineMainText {
  font-weight: 500;
}

/*  ---- homepage card styles-------- */
.vistaHomePageCard {
  border: 0px !important;
}

.vistaHomePageCardHeader {
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 3px solid #47ABE1;
}

.vistaHomePageCardBody {
  background: #f9f9f9;
  padding-left: 0px;
  padding-right: 0px;
  color: #070B31;
}

/* mark favourite compoenet */

/* .star-rating {
  border:solid 1px #ccc;
  display:flex;
  flex-direction: row-reverse;
  font-size:1.5em;
  justify-content:space-around;
  padding:0 .2em;
  text-align:center;
  width:5em;
} */

.star-rating {
  font-size: 1.7em;
  margin-top: -5px;
}

.star-rating input {
  display: none;
}

.star-rating label {
  color: #ccc;
  cursor: pointer;
}

.star-rating :checked~label {
  color: #f90;
}

.star-rating label:hover,
.star-rating label:hover~label {
  color: #fc0;
}

/* ---------------- sliding panel ---------------------------------- */

.modal-dialog.modal-dialog-slideout {
  box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
  background: #fff;
}

.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(100%, 0) scale(1);
  transform: translate(100%, 0) scale(1);
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
}

.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-dialog-slideout .modal-content {
  border: 0;
}

.modal-dialog-slideout .modal-header,
.modal-dialog-slideout .modal-footer {
  height: 69px;
  display: block;
}

.modal-dialog-slideout .modal-header h5 {
  float: left;
}

/* --------------------------- guest page ------------------------------------ */


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}

/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}

/* rtl:end:ignore */


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0;
  /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
/* rtl:begin:remove */
.featurette-heading {
  letter-spacing: -.05rem;
}

/* rtl:end:remove */

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {

  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

.vistaMarkImage {
  background-color: transparent;
  border-radius: 0;
  border: 0px;
}

.bl-social-links a {
  font-size: 1rem;
  display: inline-block;
  color: var(--black-40, rgba(28, 28, 28, 0.40)) !important;
  line-height: 0;
  margin-right: 1rem;
  transition: 0.3s;
  cursor: pointer;
}

.bl-accordion-icon {
  float: right;
  border: 1px solid;
  padding: 0 0.25rem;
  border-radius: 2px;
  cursor: pointer;
}

.bl-accordion-icon .collapsed {
  transform: rotate(270deg);
}

.bl-company-profile img {
  height: 3.625rem;
}

.bl-individual-section div {
  display: flex;
  gap: 1rem;
  place-items: center;
  padding: 0.8rem;
}

.bl-user-permission img {
  height: 2rem;
  width: 2rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.bl-activity-item {
  gap: 0.5rem;
  position: relative;
}

.bl-activity-badge {
  height: 1rem;
  width: 1rem;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 0 0 5px white;
  background: center/contain no-repeat url('https://static.wixstatic.com/media/23081b_28d5f988ed4341d395e146b4b0590709~mv2.png/v1/crop/x_16,y_0,w_468,h_500/fill/w_538,h_600,al_c,lg_1,q_85,enc_auto/1679416083312-removebg-preview_edited_pn.png');
}

.bl-activity-item::before {
  content: "";
  height: 90%;
  width: 1px;
  background-color: #1C1C1C1A;
  position: absolute;
  transform: translateX(0.4rem);
  bottom: 0;
}

.bl-activity-item:last-child::before {
  height: 0;
}

.bl-filter-clear {
  color: #E94359 !important;
}

.btn-primary-login {
  background-color: #8b8b8ca8;
  border-color: #8b8b8ca8;
  color: #fff;
}

.btn-primary-login:hover {
  background: #0e0f10;
}

.bl-overview-img {
  height: 4.5rem;
}

.bl-report-thumbnail {
  height: 2rem;
  flex-shrink: 0;
}

.bl-chip {
  border-radius: 3.75rem;
  display: inline-flex;
  padding: 0.3125rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  color: #232323;
  margin-right: 0.25rem;
  border: 1px solid #232323;
  font-weight: 500;
}

.bl-filter-chip {
  display: flex;
  padding: 0.4rem 1.0625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 2.5rem;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #F1F1F1;
  color: #232323;
  margin-right: 0.25rem;
  font-weight: 600 !important;
}

.bl-filter-chip.active {
  background: #232323;
  color: #FFFFFF;
}

.bl-activity {
  display: flex;
  padding: var(--spacing-4, 0.25rem);
  align-items: flex-start;
  gap: var(--spacing-8, 0.5rem);
  align-self: stretch;
  font-size: 12px;
}

.bl-user-activity-icon {
  padding: 0.25rem;
  background: #b7d9f8;
  height: 1.2rem;
  width: 1.2rem;
}

.bl-w-17rem {
  width: 17rem !important;
}

.bl-ml-17rem {
  margin-left: 17rem !important;
}

.bl-grid-img {
  height: 9.375rem;
  object-fit: fill;
}

.bl-catalog-category-view table tbody tr:last-child td {
  border-bottom: none;
}

.bl-report-count {
  /* float: right; */
  background: #fff;
  color: #232323;
  padding: 0 0.2rem;
  border-radius: 4px;
  font-size: 10px !important;
}

.mr-16 {
  margin-right: 4rem !important;
}

figure pre {
  text-wrap: inherit;
}

table.bl-company-overview-risk {
  border-collapse: inherit;
  border-spacing: 15px;
}

table.bl-company-overview-risk tr td {
  border: 1px solid #e3e6f0;
  border-radius: 6px;
}


/* SWITCH BUTTON */

.bl-switch {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 14px;
}

.bl-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.bl-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.bl-slider:before {
  position: absolute;
  bottom: 2px;
  content: "";
  height: 10px;
  left: 3px;
  width: 10px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.bl-slider {
  background-color: #2196F3;
}

input:focus+.bl-slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.bl-slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.bl-slider.round {
  border-radius: 34px;
}

.bl-slider.round:before {
  border-radius: 50%;
}

.bl-card-deck {
  margin: 0;
  gap: 1%;
}

.bl-ai-card-deck {
  margin: 0;
  gap: 1%;
}

.bl-ai-card-deck .card {
  min-width: 49%;
  max-width: 50%;
  margin: 0;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .bl-card-deck .card {
    min-width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 768px) {
  .bl-card-deck .card {
    min-width: 49%;
    max-width: 50%;
    margin: 0;
    margin-bottom: 1.5rem;
  }

  .bl-app-card {
    min-width: 23%;
    max-width: 23%;
    margin-left: 1%;
    margin-right: 1%;
  }

  .bl-recommended {
    max-width: 31%;
    min-width: 31%;
    margin-left: 1% !important;
    margin-right: 1% !important;
  }

  .bl-ai-model-card-deck {
    max-width: 48%;
    min-width: 48%;
    margin-left: 1% !important;
    margin-right: 1% !important;
  }
}

@media (min-width: 992px) {
  .bl-card-deck .card {
    min-width: 32%;
    max-width: 32%;
    margin: 0;
    margin-bottom: 1.5rem;
  }

  .bl-app-card {
    min-width: 15%;
    max-width: 15%;
    margin-left: 1%;
    margin-right: 1%;
  }

  .bl-recommended {
    max-width: 23%;
    min-width: 23%;
    margin-left: 1% !important;
    margin-right: 1% !important;
  }

  .bl-ai-model-card-deck {
    max-width: 32%;
    min-width: 32%;
    margin-left: 0.6% !important;
    margin-right: 0.6% !important;
  }
}

@media (min-width: 1200px) {
  .bl-card-deck .card {
    min-width: 24%;
    max-width: 24%;
    margin: 0;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1600px) {
  .bl-product-card {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.bl-rounded-info {
  background: #67b7dc;
  border-radius: 50%;
  color: #fff;
  padding: 5px;
}

.bl-w-15per {
  width: 15%;
}

.bl-w-5rem {
  width: 5rem;
}

.bl-desc-truncate-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bl-desc-truncate-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bl-h-2 {
  height: 2.5rem;
}

.bl-h-1-4rem {
  height: 1.4rem;
}

.bl-h-1-9rem {
  height: 1.9rem;
}

.bl-minh-10rem {
  min-height: 10rem;
}

.d-grid {
  display: grid;
}

.w-40 {
  width: 40% !important;
}

.bl-text-due-deligence {
  color: #0B84A5;
}

.bl-text-negotiation {
  color: #B47E04;
}

.bl-text-closed {
  color: #6F4E7C;
}

.bl-modal-context {
  background: #ffffffbf;
  backdrop-filter: blur(6px);
}

.bl-min-vh-95 {
  min-height: 95vh;
}

.bl-min-vh-94 {
  height: 88vh;
}

.bl-modal-action {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  width: auto;
}

.bl-search-footer {
  position: sticky;
  bottom: 20px;
  left: 10px;
  right: 10px;
  border-radius: 10px;
}

.bl-rotate-175 {
  transform: rotate(175deg);
}

.bl-rotate-180 {
  transform: rotate(180deg);
}

.bl-rotate-riverse-y {
  transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
}

.bl-modal-action {
  z-index: 9999;
}

.bl-modal-content {
  position: absolute;
  width: 100%;
  bottom: 3rem;
}

.active-menu img,
.dropdown-item img {
  width: 1.1rem;
}

.w-1-1rem {
  width: 1.1rem;
}

.bl-h-1-25rem {
  height: 2rem;
}

.bl-border-bottom-grad {
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, #d9d9d900, #d9d9d9, #d9d9d900) 1;
}

.bl-border-right-grad {
  border-right: 1px solid;
  border-image: linear-gradient(180deg, #d9d9d900, #d9d9d9) 1;
}

.bl-border-right-grad-2 {
  border-right: 1px solid;
  border-image: linear-gradient(180deg, #d9d9d9, #d9d9d900) 1;
}

.bl-w-3rem {
  width: 3rem;
}

.bl-ai-sidebar {
  width: 17rem !important;
  min-height: 95.3vh;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 10px;
}

.bl-min-vh-98 {
  min-height: 98vh;
}

.bl-vh-98 {
  height: 98vh;
}

.bl-vh-95 {
  height: 95vh;
}

.bl-card-visibility {
  transform: translateZ(0)
}

.bl-minimize-modal {
  width: 0 !important;
  height: 0 !important;
}

.bl-minimize-content {
  min-height: 2rem !important;
  position: fixed;
  width: 15rem;
  bottom: 0;
  right: 20px;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.bl-mw-90 {
  max-width: 90% !important;
}

.bl-mw-75 {
  max-width: 75% !important;
}

.bl-mw-30rem {
  max-width: 30rem;
}

.bl-mt-10rem {
  margin-top: 10rem;
}

.bl-mb-20rem {
  margin-bottom: 20rem;
}

.bl-mb-15rem {
  margin-bottom: 15rem;
}

.bl-mt-20 {
  margin-top: 20px;
}

.bl-pt-20 {
  padding-top: 20px;
}

.bl-assistant-sec {
  border-bottom: 1px solid var(--black-10, rgba(0, 0, 0, 0.10));
  border-top: 1px solid var(--black-10, rgba(0, 0, 0, 0.10));
  background: rgba(233, 67, 89, 0.03);
}

.bl-ai-insight {
  border-radius: 0.25rem;
  border: 1px solid #FAD207;
  background: rgba(250, 210, 7, 0.08);
}

.bl-body-hover:hover {
  background: #fafafa;
}

.bl-source-stage-body {
  height: 100%;
  display: grid;
  grid-template-rows: 40% 60%;
}

.bl-company-source-stage {
  height: 0.4375rem;
  align-self: center;
  min-width: 20%;
}

.bl-prospects {
  background-color: #F6C85F;
  border-color: #F6C85F;
}

.bl-due-diligence {
  background-color: #69DEFE;
  border-color: #69DEFE;
}

.bl-negotiation {
  background-color: #0B84A5;
  border-color: #0B84A5;
}

.bl-closed {
  background-color: #DE89FF;
  border-color: #DE89FF;
}

.bl-default-status {
  background-color: #F1F1F1;
  border-color: #F1F1F1;
}

.bl-h-10px {
  height: 0.7rem !important;
}

.tooltip-inner {
  font-size: x-small !important;
}

.bl-text-underline-hover:hover {
  text-decoration: underline;
  color: blue;
}

.bl-h-3rem {
  height: 3rem;
}

.bl-app-pinned-pos {
  position: absolute;
  top: 10px;
  right: 10px;
}

.left-100 {
  left: 100% !important;
}

.bl-recommended-app-icon {
  width: 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
}

.bl-w-9rem {
  width: 9rem;
}

.bl-rule-box1 {
  border-bottom: 3px solid #FF403B;
}

.bl-rule-box2 {
  border-bottom: 3px solid #FFBA39;
}

.bl-rule-box3 {
  border-bottom: 3px solid #8AD129;
}

.bl-task-category {
  height: 2rem;
  width: 2rem;
}

.tooltip {
  z-index: 9 !important;
}

.bl-h-4rem {
  height: 4rem;
}

@media (max-width: 1007px) {
  .bl-mid-hide {
    display: none;
  }

  .mob-hide {
    display: none;
  }
}

.bl-content-overview {
  border-radius: 0.375rem;
  border: 1px solid var(--black-10, rgba(0, 0, 0, 0.10));
  background: url(./../assets/img/BG.webp) 50% / cover no-repeat;
}

.nav-item .nav-content {
  margin: 0px 0.3rem !important;
}

.nav-content li {
  list-style: none;
}

.bl-text-purple {
  color: #6a2f5f !important;
}

.bl-bg-purple {
  background-color: #953f98 !important;
}

.bl-border-purple {
  border: 1px solid #6a2f5f;
}

.bl-border-bottom-purple {
  border-bottom: 1px solid #6a2f5f;
}

.bl-border-right-purple {
  border-right: 1px solid #6a2f5f;
}

.bl-border-left-purple {
  border-left: 1px solid #6a2f5f;
}

.bl-border-color-purple {
  border-color: #6a2f5f !important;
}

.bl-border-radius-5px {
  border-radius: 5px;
}

.bl-jf-logo {
  height: 0.9rem !important;
}

.bl-product-checkbox {
  position: absolute;
  top: 15px;
  left: 20px;
  z-index: 9;
}

.bl-product-count-action {
  width: 5rem;
  position: absolute;
  right: 15px;
  z-index: 9;
}

.bl-lineheight-45px {
  line-height: 45px !important;
}

.bl-lineheight-36px {
  line-height: 36px !important;
}

.bl-lineheight-23px {
  line-height: 23px !important;
}

.bl-lineheight-18px {
  line-height: 18px !important;
}

.bl-font-larger {
  font-size: larger !important;
}

/* PRODUCT LABEL STARTS HERE */

.bl-product-category {
  font-family: ozik-medium;
  font-size: 16pt !important;
}

.bl-ozik-medium-20 {
  font-family: ozik-medium;
  font-size: 20pt !important;
}

.bl-ozik-medium-23pt {
  font-family: ozik-medium;
  font-size: 23pt !important;
}

.bl-ozik-medium-21pt {
  font-family: ozik-medium;
  font-size: 21pt !important;
}

.bl-product-strain {
  font-family: barlow-bold;
  font-size: 10pt !important;
}

.bl-ozik-bold-35pt {
  font-family: ozik-bold;
  font-size: 35pt !important;
}

.bl-product-name {
  font-family: ozik-bold;
  font-size: 27pt !important;
}

.bl-ozik-bold-14pt {
  font-family: ozik-bold;
  font-size: 14pt !important;
}

.bl-ozik-bold-20pt {
  font-family: ozik-bold;
  font-size: 20pt !important;
}

.bl-product-brand {
  font-family: ozik-medium;
  font-size: 16pt !important;
}

.bl-ozik-medium-18pt {
  font-family: ozik-medium;
  font-size: 18pt !important;
}

.bl-ozik-medium-20pt {
  font-family: ozik-medium;
  font-size: 20pt !important;
}

.bl-product-info {
  font-family: barlow-bold;
  font-size: 26pt !important;
}

.bl-barlow-bold-35pt {
  font-family: barlow-bold;
  font-size: 35pt !important;
}

.bl-barlow-bold-18pt {
  font-family: barlow-bold;
  font-size: 18pt !important;
}

.bl-barlow-bold-16pt {
  font-family: barlow-bold;
  font-size: 16pt !important;
}

.bl-barlow-bold-14pt {
  font-family: barlow-bold;
  font-size: 14pt !important;
}

.bl-product-thc {
  font-family: barlow-bold;
  font-size: 12pt !important;
}

.bl-product-id {
  font-family: barlow-medium;
  font-size: 18pt !important;
}

.bl-barlow-medium-26pt {
  font-family: barlow-medium;
  font-size: 26pt !important;
}

.bl-barlow-medium-12pt {
  font-family: barlow-medium;
  font-size: 12pt !important;
}

.bl-barlow-medium-14pt {
  font-family: barlow-medium;
  font-size: 14pt !important;
}

.bl-product-description {
  font-family: barlow-medium;
  font-size: 12pt !important;
}

.bl-barlow-medium-15pt {
  font-family: barlow-medium;
  font-size: 15pt !important;
}

@media print {
  @page {
    size: 210mm 297mm;
  }

  html,
  body {
    height: 1120px;
    margin: 0 !important;
    padding: 0 5px !important;
  }

  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}

.bl-print-card {
  width: 33.33%;
  height: 518px;
  padding: 10px 10px 0 10px;
}

.bl-card-preview .bl-print-card {
  width: 100% !important;
  height: auto;
  border: 0 !important;
  /* padding: 0.3rem; */
}

.bl-height-120px {
  height: 120px !important;
}

.bl-height-90px {
  height: 90px !important;
}

.bl-height-85px {
  height: 85px !important;
}

.bl-height-80px {
  height: 80px !important;
}

.bl-height-70px {
  height: 70px !important;
}

.bl-height-62px {
  height: 62px !important;
}

.bl-height-60px {
  height: 60px !important;
}

.bl-height-55px {
  height: 55px !important;
}

.bl-height-50px {
  height: 50px !important;
}

.bl-height-40px {
  height: 40px !important;
}

.bl-height-35px {
  height: 35px !important;
}


/* PRODUCT LABEL END HERE */

.bl-min-width-19 {
  min-width: 19%;
}

.bl-list-loader {
  width: 100%;
  height: 10rem;
}

.bl-sort-icon {
  height: 0.7rem !important;
}

.bl-h-15rem {
  height: 15rem;
}

.bl-top-75 {
  top: 75% !important;
}

.bl-bottom-0 {
  bottom: 0;
}

.bl-profile-circle {
  border-radius: 50% !important;
  padding: 10px;
  color: #953f98;
  font-weight: 500;
  font-size: 10px;
  border: 1px solid;
}

.bl-login-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px;
  /* Vertically center the text there */
  background-color: #f5f5f53b !important;
  border-top: 0.5px solid #fff;
}

.bl-w-fill-available {
  width: -webkit-fill-available;
}

.bl-sticky-title {
  position: fixed;
  top: 54px;
  width: -webkit-fill-available;
  z-index: 1000;
  background: white;
  padding: 10px 18px 10px 0;
  border: 1px solid white;
}

.bl-menu-footer {
  z-index: 100;
  background: #953f98;
  width: inherit;
}

.h-82vh {
  height: 82vh !important;
}

.bl-h-fit-content {
  height: fit-content !important;
}

.bl-break-line {
  width: 2px;
  background-color: #dcdcdc;
  margin: 0.25rem 2rem 0.25rem 1rem;
}

.bl-font-14px {
  font-size: 0.875rem !important;
}

.bl-list-print-page-size{
  width: 576px !important;
}