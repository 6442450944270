@import url('./css/bootstrap-styles.css');
@import url('./css/bl-custom-styles.css');
@import url('./js/font-awesome-4.7.0/css/font-awesome.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/css/flag-icon.min.css');

@font-face {
  font-family: ozik-bold;
  src: url('./fonts/OZIK-Bold.otf');
}

@font-face {
  font-family: ozik-medium;
  src: url('./fonts/OZIK-Medium.otf');
}

@font-face {
  font-family: barlow-bold;
  src: url('./fonts/BarlowCondensed-Bold.ttf');
}

@font-face {
  font-family: barlow-medium;
  src: url('./fonts/BarlowCondensed-Medium.ttf');
}

.product-font{
  font-family: ozik-bold;
}

.product-brand-font {
  font-family: ozik-medium;
}


body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  line-height: 1.5;
}

code {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar-nav li {
  list-style: none !important;
}

.navbar-nav .nav-link:not(.collapsed) .fa-chevron-down {
  transform: rotate(180deg);
}

.sidebar button {
  border: 0;
  background-color: transparent;
}

.mainmenu button {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mainmenu .nav-item.active .nav-link {
  font-weight: 500 !important;
}

.sidebar button:focus {
  outline: none !important;
}

.sub-menu button {
  color: #fff !important;
}

.btn-navigate {
  border: 0;
  background-color: transparent;
}

.btn-navigate:focus {
  outline: none !important;
}

.sidebar button.active {
  background-color: #d3d3d3;
}

.dropdown-menu button {
  background-color: transparent;
  color: #6c6d74;
  font-size: 0.813rem !important;
}

.dropdown-menu button:focus {
  outline: none !important;
}

.dropdown-menu button:active {
  color: #6c6d74 !important;
  background-color: transparent !important;
}

.navbar button {
  border: 0;
  background-color: transparent !important;
}

.collapsed-header-margin-sub-menu {
  left: 289px !important;
  transition: all 0.4s linear !important;
}

.collapsed-content-margin-sub-menu {
  margin-left: 289px !important;
  transition: all 0.4s linear !important;
}

.mob-collapsed-header-margin-sub-menu {
  left: 100px !important;
  transition: all 0.4s linear !important;
}

.mob-collapsed-content-margin-sub-menu {
  margin-left: 100px !important;
  transition: all 0.4s linear !important;
}

em {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  /* Apply italic style if needed */
}

.cp {
  cursor: pointer;
}

.mainmenu {
  z-index: 9;
}

.mainmenu .nav-link {
  padding: 0 0.3rem 0.8rem !important;
}

.mainmenu .sidebar-divider {
  margin: 0 1rem 0.8rem !important;
}

.mainmenu button {
  color: #943F98 !important;
}

.mainmenu .nav-item.active .active-menu {
  color: #953F98 !important;
  padding: 0.3rem;
  border-radius: 2rem;
  border: 2px solid #953F98;
  background: #FBD276;
  font-weight: 700;
}

/* .mainmenu .nav-item.active span {
  padding-top: 0.25rem;
} */

.sub-menu {
  overflow-y: auto;
  height: 100vh;
  overflow-x: hidden;
  z-index: 8;
}

.sub-menu .nav-item .nav-link {
  padding: 0.5rem 0.8rem 0.5rem 0.5rem;
  margin-left: 0.3rem;
  color: #ffffff !important;
  width: 100%;
}

.sub-menu .sidebar-heading {
  text-align: left !important;
  color: #ffffff99 !important;
}

.sub-menu .nav-link.collapsed {
  color: #ffffff99 !important;
}

.sub-menu .nav-item.selected button {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  color: #ffffff !important;
  font-weight: 600;
}

.sub-menu .nav-content {
  position: relative !important;
  /* left: 10px !important; */
}

.sub-menu .nav-content .nav-link {
  text-align: left !important;
}

.bg-gray {
  background: #FAFAFA;
}

li a {
  cursor: pointer;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.mobile .mob-hide {
  display: none;
}

.mobile .dash-title {
  overflow: auto;
}

.dash-title h4 {
  white-space: nowrap;
}


/* Button Behaviours change */

.btn-filter {
  background: #EE7B8B !important;
  border-color: #E94359 !important;
  color: #ffffff !important;
}

.btn-filter:hover {
  background: #f08594 !important;
  color: #ffffff !important;
}

.btn-filter:focus {
  box-shadow: 0 0 0 0.2rem #f08594 !important;
}

.filter-btn button {
  line-height: 0.5;
  padding: 0.65rem;
  margin-top: 12px;
}

.clear-btn {
  margin-top: 10px;
  font-weight: 600;
}

.font-small {
  font-size: 90%;
}

.font-smaller {
  font-size: 80%;
}


/* p-TreeTable */
.p-treetable {
  font-family: 'Open Sans', sans-serif !important;
  min-width: 20rem;
}

.p-treetable .p-treetable-thead>tr>th {
  padding: 0.5rem !important;
}

.p-treetable .p-treetable-tbody>tr>td {
  padding: 0.5rem !important;
}

.p-treetable-table {
  font-size: 0.75rem !important;
}

.p-checkbox {
  display: grid;
  place-items: center;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid !important;
  border-radius: 2px !important;
  height: 13px !important;
  width: 13px !important;
}

.p-inputtext {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid !important;
  border-radius: 3px !important;
  font-size: 12px !important;
  padding: 0.3rem !important;
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
  height: 10px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.p-inputtext {
  width: 100% !important;
}

.p-treetable .p-treetable-thead>tr>th {
  background: transparent !important;
  border-width: 0 0 2px 0 !important;
}

.p-treetable .p-treetable-tbody tr:hover {
  background: #fafafa;
}



.p-datatable {
  font-family: 'Open Sans', sans-serif !important;
  min-width: 20rem;
}

.p-datatable .p-datatable-thead>tr>th {
  padding: 0.5rem !important;
}

.p-datatable .p-datatable-tbody>tr>td {
  padding: 0.5rem !important;
}

.p-datatable-table {
  font-size: 0.75rem !important;
}

.p-datatable .p-datatable-thead>tr>th {
  background: transparent !important;
  border-width: 0 0 2px 0 !important;
}

.p-datatable .p-datatable-tbody tr:hover {
  background: #fafafa;
}

.p-datatable .p-datatable-tfoot>tr>td {
  background: #fff !important;
  padding: 0.5rem !important;
}

.p-datatable .p-datatable-tbody>tr:last-child>td {
  border: none !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  height: 10px !important;
}

/* Custom styles for the offcanvas */
.offcanvas {
  /* width: 250px; Set a custom width */
  margin-top: 54px;
}

/* Customize the transition animation */
.offcanvas.show {
  transform: translateX(0);
}

.footer {
  position: absolute;
  bottom: 1px;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px;
}